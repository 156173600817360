$amp: '.faq';

#{$amp} {
    width: 100%;
    margin: 30px 0;

    &__question {
        font-size: 14px;
        padding: 5px;
        border: 1px solid black;
        padding: 10px;
        color: black;
        margin-bottom: 2px;
        border: 1px solid #f2f2f2;
        background: #f7f7f7;
        font-weight: $fw-semibold;
        font-family: $ff-os;
        cursor: pointer;
        position: relative;
        line-height: 20px;
        padding-right: 30px;

        &::after {
            content: '\f067';
            font-family: $ff-fa;
            position: absolute;
            right: 10px;
            top: 50%;
            transform: translateY(-50%);
            font-size: 10px;
        }
        
        &--open {

            &::after {
                content: '\f068';
            }
        }
    }

    &__answer {
        display: none;
        width: 100%;
        margin: 0 auto;
        padding: 20px;
        margin-bottom: 10px;
        font-family: $ff-ro;
        font-size: 14px;

        @include desktop {
            width: 96%;
        }
    }
}