.magazin-end {
    .magazin-year {

        display: flex;
        flex-flow: column;
        font-family: $ff-os;
        font-weight: $fw-bold;
        font-size: 13px;
        color: #FFFFFF;
        width: 100%;
        background-image: linear-gradient(90deg, #738D40 16%, #FFFFFF 100%);
        border-radius: 2px;
        padding: 12px 20px;
        position: relative;
        margin-bottom: -1px;

        @include tablet {
            > span {
                display: block;
                margin-bottom: 0;
            }
        }
    }
    .price {
        text-align: center;
        margin-bottom: 20px;
        font-size: 16px;
        color: #8c8c8c;
    }

    .button {
        display: inline-block;
        padding: 11px 28px;
        text-align: center;
        margin: 0 auto;
        border-radius: 4px;
        font-size: 12px;
    }
}
