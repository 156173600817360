@mixin phone {
    @media only screen and (min-width:map-get($breakpoints, 'mobil')){
        @content;    
    }
}
@mixin tablet {
    @media only screen and (min-width:map-get($breakpoints, 'tablet')){
        @content;    
    }
}
@mixin desktop {
    @media only screen and (min-width:map-get($breakpoints, 'desktop')){
        @content;    
    }
}

@mixin lg-desktop {
    @media only screen and (min-width:map-get($breakpoints, 'lg-desktop')){
        @content;    
    }
}
@mixin clearfix{
    *zoom: 1;
    &:before,
    &:after{
        content : ' ';
        display: table;
    }
    &:after{
        clear: both;
    }
}
@mixin border-box{
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

@function calc-em($target-px, $context) {
    @return ($target-px / $context) * 1em;
}
  