$amp: '.reference';

#{$amp} {

    margin-bottom: 20px;
    padding: 10px 20px;
    width: 100%;

    &__img {
        width: 100%;
        margin-bottom: 7px;
        border-radius: 4px;
    }

    &__title {
        display: block;
        font-size: 14px;
        font-weight: $fw-semibold;
        color: #000000;
        margin-bottom: 5px;

        @include desktop {
            font-size: 16px;
        }
    }

    &__desc {
        font-size: 12px;
        color: #000000;
        margin-bottom: 5px;

        @include desktop {
            font-size: 14px;
        }
    }
}