$amp: '#cookie-bar';

#{$amp} {

    background-color: rgba(51, 51, 51, 0.9);
    width: 100%; color: white;
    z-index: 10000000;
    padding: 15px 25px;

    p {
        max-width: 800px;
        margin: 0 auto;
        font-family: $ff-ro;
        font-size: 14px;
        display: block;
        line-height: 18px;
        font-weight: $fw-bold;
    }

    .cb-enable {
        max-width: 120px;
        display: block;
        margin: 0 auto;
        font-family: $ff-ow;
        font-size: 14px;
        background: #027e55;
        border-bottom: 3px solid #033c29;
        color: $c-white;
        padding: 10px 30px;
        cursor: pointer;
        display: block;
        text-align: center;
        margin: 10px auto;
        transition: .4s;

        &:hover,
        &:active,
        &:focus {
            color: $c-white;
            background: #027e55;
            text-decoration: none;
            transform: scale(0.95);
        }
    }
    
    .cb-policy {
        font-size: 14px;
        font-family: $ff-ro;
        color: #ffffff;
        text-align: center;
        background: transparent;
        text-decoration: underline;

        &:hover,
        &:focus,
        &:visited {
            background: transparent;
        }
    }
}