$amp : '.pagination';


#{$amp} { 
    width: 100%;
    margin: 40px auto;
    color: #676666;
    text-align: center;
    
    
    @include desktop {
        padding-bottom: 16px;
    }
    &__wrapper {
        display: inline-block;
        padding: 10px 20px;
        border: 1px solid #004830;
        border-radius: 10px;
        border-top: 3px solid #004830;
    }
    &__btn-wrapper {
        width: 34px;
        height: 36px;
        display: inline-block;
        text-align: center;
        line-height: 36px;
        font-size: 14px;
        cursor: pointer;
        color: #004830;

        &:hover {
            text-decoration: none;
            color: #f8c446;
        }

        &__prev {
            background: url("../../web/images/svg-btPrev.svg") no-repeat center center;
            background-size: 16px;
            transform: translateY(35%);
        }

        &__next {
            background: url("../../web/images/svg-btNext.svg") no-repeat center center;
            background-size: 16px;
            transform: translateY(35%);
        }
        &__more {
            user-select: none;
            cursor: auto;
            width: 12px;
        }
        &__current {
            background-color: #004830;
            color: white;
            border-radius: 3px; 
        }
    }
    
    &__form {
        margin-top: 10px;
        width: 36px;

        display: inline-block;
        @include desktop {
            
            margin: 0;
            margin-top: 0;
        }
    }
    &__form input {
        display: block;
        width: 100%;
        height: 36px;
        border: 1px solid #505050;
        border-radius: 4px;
        text-align: center;
        outline-color: #0572a6;
        color: #676666;
    }
}