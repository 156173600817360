$amp: '.sidebar-box';

#{$amp} {
    display: flex;
    justify-content: center;
    flex-flow: column;
    margin-bottom: 10px;

    &:hover, &:focus, &:visited {
        text-decoration: none;
    }

    &__title {
        display: block;
        background: #769D3E;
        box-shadow: 0 2px 4px 0 rgba(0,0,0,0.50);
        border-radius: 4px;
        font-family: $ff-os;
        font-weight: $fw-bold;
        font-size: 13px;
        line-height: 17px;
        color: #FFFFFF;
        letter-spacing: 0.71px;
        text-transform: uppercase;
        padding: 10px 20px;
        text-align: center;
        cursor: pointer;
        user-select: none;
        margin: 0 auto;
        width: 240px;

        > i {
            margin-right: 10px;
        }
    }

    &__magazin {
        box-shadow: 3px 3px 4px 0 rgba(0, 0, 0, 0.27);
        width: 220px;
        height: 320px;
        overflow: hidden;
        margin: 0 auto;
        position: relative;
        background-size: cover;
    }
}