$amp: '.calendar';

#{$amp} {

    &__header {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 30px;
        position: relative;
        flex-wrap: wrap;

        @include phone {
            justify-content: flex-start;
        }
    }

    &__month {
        font-family: $ff-os;
        font-weight: $fw-semibold;
        font-size: 16px;
        margin: 5px;
        color: #888888;
        line-height: 20px;
        padding: 10px 20px;
        min-width: 250px;
        text-align: center;
        text-transform: uppercase;
        letter-spacing: 5px;

        &:before {
            content: '\f073';
            font-family: $ff-fa;
            margin-right: 8px;
        }

        @include phone {
            font-size: 18px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            margin: 0;
        }
    }

    &__prev {
        margin-right: 20px;
        width: 40px;
        height: 40px;
        cursor: pointer;
        user-select: none;

        img {
            width: 100%;
        }
    }

    &__next {
        width: 40px;
        height: 40px;
        cursor: pointer;
        user-select: none;

        img {
            width: 100%;
        }
    }

    &__row {
        display: flex;
    }
}