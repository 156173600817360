$amp : '.news-item';

#{$amp} {

    display: block;
    width: 100%;
    margin-bottom: 30px;

    &__image {
        box-shadow: 0 2px 4px 0 rgba(0,0,0,0.50);
        border-radius: 4px;
        width: 100%;
        max-width: 600px;
        margin: 0 auto 13px;
        height: 220px;
        background-size: cover;
        background-position: center;
    }

    &__title {
        display: block;
        font-family: $ff-os;
        font-weight: $fw-semibold;
        font-size: 14px;
        color: #000000;
        margin-bottom: 10px;
        line-height: 20px;

        @include tablet {
            font-size: 16px;
        }
    }

    &__date {
        display: block;
        font-family: $ff-ro;
        font-weight: $fw-regular;
        font-size: 12px;
        color: #81a746;
        margin-bottom: 10px;

        &:before {
            content: '\f073';
            font-family: $ff-fa;
            margin-right: 8px;
            font-weight: $fw-light;
        }
    }

    &__lead {
        font-family: $ff-ro;
        font-weight: $fw-regular;
        font-size: 14px;
        color: #545454;
        margin-bottom: 15px;
        line-height: 20px;
    }

    &__more {
        
        font-family: $ff-os;
        font-weight: $fw-bold;
        font-size: 12px;
        color: #D0D0D0;
        background: #ffffff; 
        border: 1px solid #f8c446;
        border-radius: 6px;
        padding: 8px 30px;
        cursor: pointer;
        display: inline-block;
        outline: none;

        &:hover {
            color: #ffffff!important;
            background: #f8c446;
            text-decoration: none;
            outline: none;
        }
    }
}