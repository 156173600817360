.card {
    box-shadow: 0px 4px 0 0px #738d40;
}

.card-title {
    font-size: 13px;
    color: #313131;
    font-weight: 400;

    > i {
        margin-right: 10px;
    }
}