$amp: '.carrier';

#{$amp} {

    margin-bottom: 60px;

    &__title {
        display: block;
        font-family: $ff-os;
        font-weight: $fw-semibold;
        font-size: 14px;
        color: #000000;
        margin-bottom: 7px;
        line-height: 20px;

        @include tablet {
            font-size: 16px;
        }
    }

    &__time {
        display: block;
        font-family: $ff-ro;
        font-weight: $fw-light;
        font-size: 12px;
        color: #000000;
        margin-bottom: 10px;

        &:before {
            content: '\f017';
            font-family: $ff-fa;
            font-style: normal;
            margin-right: 8px;
            font-weight: $fw-light;
        }
    }

    &__lead {
        font-family: $ff-ro;
        font-weight: $fw-regular;
        font-size: 12px;
        color: #948F8F;
        margin-bottom: 20px;
        line-height: 18px;

        @include tablet {
            font-size: 14px;
        }
    }

    &__more {
        font-family: $ff-os;
        font-weight: $fw-bold;
        font-size: 12px;
        color: #D0D0D0;
        background: #ffffff; 
        border: 1px solid #f8c446;
        border-radius: 6px;
        padding: 5px 30px;
        cursor: pointer;

        &:hover {
            color: #ffffff!important;
            background: #f8c446;
            text-decoration: none;
        }
    }
} 