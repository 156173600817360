$amp: '.partners';

#{$amp} {

    @include tablet {
        padding: 20px 0;
    }

    &__title {
        font-family: $ff-os;
        font-weight: $fw-semibold;
        font-size: 20px;
        color: #000000;
        text-transform: uppercase;
        min-width: 120px;
        margin-bottom: 20px;

        &::after {
            content: "";
            display: block;
            height: 20px;
            background-image: url("../../web/images/bottom-line.svg");
            background-repeat: no-repeat;
            background-position: left bottom;
        }
    }

    &__desc {
        font-family: $ff-os;
        font-weight: $fw-semibold;
        font-size: 14px;
        color: #9C9C9C;
        letter-spacing: 0.71px;
        line-height: 19px;

        hr {
            margin-top: 17px!important;
            background: #f7f7f7;
        }
    }

    &__wrapper {
       display: flex;
       align-items: center;
       width: 100%;
       position: relative;
    }

    &__item {
        margin: 0 2px;
    }

    &__prev {
        margin-right: 10px;
        margin-top: 20px;
        
        @include tablet {
            margin-right: 80px;
        }
    }

    &__next {
        margin-left: 10px;
        margin-top: 20px;

        @include tablet {
            margin-left: 80px;
        }
    }
}