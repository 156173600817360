$amp: '.attachment';

#{$amp} {

    &__title {
        font-family: $ff-os;
        font-weight: $fw-semibold;
        font-size: 16px;
        color: #000000;
        text-transform: uppercase;
        min-width: 120px;
        margin-bottom: 15px;

        &::after {
            content: "";
            display: block;
            height: 20px;
            background-image: url("../../web/images/bottom-line.svg");
            background-repeat: no-repeat;
            background-position: left bottom;
        }
    }

    &__gallery {
        width: 100%;
        overflow: hidden;
        // height: 315px;
        position: relative;
        margin-bottom: 30px;
        
        img {
            width: 100%;
            transition: 0.2s;

            &:hover {
                transform: scale(1.1);
            }
        }
        &::after {
            content: '\f002';
            font-family: $ff-fa;
            font-size: 16px;
            position: absolute;
            top: 20px;
            right: 20px;
            cursor: pointer;
        }
    }

    &__video {
        position: relative;
        padding-bottom: 56.25%; /* 16:9 */
        padding-top: 25px;
        height: 0;
        margin-bottom: 30px;

        &--bg {
            background: #ffffff;
            width: 100%;
            height: 200px;
            border: 1px solid #f2f2f2;
            &::after {
                content: '\f167';
                font-family: $ff-brands;
                font-size: 50px;
                color: #ff0001;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%,-50%);
    
            }
        }

        > iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }

    &__caption {
        position: absolute;
        bottom: 0;
        width: 100%;
        padding: 10px 20px;
        background: rgba(115, 141, 64, 1);
        color: #ffffff;
        font-weight: $fw-semibold;
    }
}