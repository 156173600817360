$amp: '.events-box';

#{$amp} {
    padding: 20px 10px;
    border-top: 1px solid black;

    @include desktop {
        padding: 20px;
    }

    animation:fade 1s ease;

    @keyframes fade {
        0% { opacity:0; }
        100%{ opacity:1; }
    }

    &__item {
        //border-bottom: 1px solid #efeaea;
        margin-bottom: 20px;
    }

    &__city {
        font-family: $ff-os;
        font-weight: $fw-semibold;
        font-size: 20px;
        color: #000000;
        text-transform: uppercase;
        min-width: 120px;
        margin-bottom: 10px;

        &::after {
            content: "";
            display: block;
            height: 10px;
            background-image: url("../../web/images/bottom-line.svg");
            background-repeat: no-repeat;
            background-position: left bottom;
        }
    }
    &__image {
        margin: 0 auto;
        display: block;
        width: 300px;
        height: 157px;
        overflow: hidden;
        margin-bottom: 20px;
        max-width: 100%;

        @include tablet {
            float: left;
            margin-right: 40px;
        }

        img {
            width: 100%;
            border-radius: 4px;
        }
    }

    &__content {

        @include tablet {
            padding-left: 340px;
        }
    }

    &__title {
        display: block;
        font-family: $ff-os;
        font-weight: $fw-semibold;
        font-size: 14px;
        color: #000000;
        letter-spacing: calc-em(1.02px, 14px);
        margin-bottom: 7px;
        line-height: 20px;

        @include tablet {
            font-size: 16px;
        }
    }

    &__date {
        display: block;
        font-family: $ff-ro;
        font-weight: $fw-light;
        font-size: 12px;
        color: #000000;
        margin-bottom: 10px;

        &:before {
            content: '\f017';
            font-family: $ff-fa;
            font-style: normal;
            margin-right: 8px;
            font-weight: $fw-light;
        }
    }

    &__lead {
        font-family: $ff-ro;
        font-weight: $fw-regular;
        font-size: 12px;
        color: #948F8F;
        margin-bottom: 20px;
        line-height: 18px;

        @include tablet {
            font-size: 14px;
        }
    }

    &__more {
        
        font-family: $ff-os;
        font-weight: $fw-bold;
        font-size: 12px;
        color: #D0D0D0;
        background: #ffffff; 
        border: 1px solid #f8c446;
        border-radius: 6px;
        padding: 5px 30px;
        cursor: pointer;
        max-width: 50px;
        display: block;
        margin-bottom: 20px;

        &:hover {
            color: #ffffff!important;
            background: #f8c446;
            text-decoration: none;
        }
    }
}