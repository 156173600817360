
// colors
$c-white: #ffffff;
$c-black: #000000;

$base-font: 'Roboto', sans-serif;
$ff-ro: 'Roboto', sans-serif;
$ff-ow: 'Oswald', sans-serif;
$ff-os: 'Open Sans', sans-serif;
$ff-fa: 'Font Awesome 5 Free';
$ff-brands: 'Font Awesome 5 Brands';

//font-weight
$fw-thin       : 100; //thin=100
$fw-extralight : 200; //extralight=200
$fw-light      : 300; //light=300
$fw-normal     : 400; //normal=400
$fw-regular    : 400; //regular=400
$fw-medium     : 500; //medium=500
$fw-semibold   : 600; //semibold=600
$fw-bold       : 700; //bold=700
$fw-heavy      : 800; //heavy=800
$fw-extrabold  : 800; //extrabold=800
$fw-black      : 900; //black=900
$fw-ultrabold  : 900; //ultrabold=900
$fw-solid      : 900; //font-awesome-weight

//Grid and Responsiv
$breakpoints: (
    mobil: 576px,
    tablet: 768px,
    desktop: 992px,
    lg-desktop: 1200px
);

$columns : 12;
$grid-max-width: 1200px;

//Heading font-size

$heading-font-size: (
    h1: 26px,
    h2: 23px,
    h3: 20px,
    h4: 17px,
    h5: 14px,
    h6: 11px
);