$amp: '.button';

#{$amp} {

    padding: 8px 16px;
    text-transform: uppercase;
    font-weight: $fw-semibold;
    font-family: $ff-os;
    font-size: 12px;
    color: #FFFFFF;
    letter-spacing: 0.71px;
    cursor: pointer;
    border-radius: 4px;
    transition: 0.4s;
    display: flex;
    align-items: center;
    outline: none;
    background-image: linear-gradient(-228deg, #879E5C 1%, #004930 82%);
    border: 1px solid #979797;

    > i {
        margin-right: 10px;
    }

    > img {
        margin-right: 10px;
    }

    &:hover,
    &:active,
    &:focus {
        transform: scale(0.95);
        text-decoration: none;
        outline: none;
    }
}