$amp: '.form';

#{$amp} {
    margin-bottom: 30px;

    &__row {
        width: 100%;

        & + hr {
            margin-top: 0;
            margin-bottom: 20px;
        }
    }

    &__title {
        font-family: $ff-ro;
        font-size: 16px;
    }

    &__item {
        display: inline-block;
        width: 100%;
        vertical-align: top;

        @include media-breakpoint-up(md) {
            & + & {
                margin-left: 20px;
            }
        }

        .button {
            //margin: 0 auto;
            padding: 7px 32px;
        }

        &.mx-width300 {
            max-width: 250px;
            margin-bottom: 15px;
        }

        line-height: 20px;
        margin-bottom: 15px;

        label {
            font-family: $ff-os;
            font-weight: $fw-semibold;
            font-size: 12px;
            margin-bottom: 5px;
            color: #464646;
        }

        input:not([type='button']):not([type='submit']):not([type='radio']):not([type='checkbox']):not([type='file']),
        textarea {
            width: 100%;
            outline: none;
            background: $c-white;
            border: 1px solid #dadada;
            border-radius: 4px;
            font-family: $ff-ro;
            padding: 10px 15px;
            color:$c-black;
            font-size: 13px;
            line-height: normal;

            @include placeholder() {
                color: #b9b9b9;
                font-weight: $fw-light;
                font-size: 12px;
            }

            &[disabled], &[readonly] {
                color:$c-black;
                background: #efefef;
            }
        }

        input[type=number]::-webkit-inner-spin-button,
        input[type=number]::-webkit-outer-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
    }

    &__alert {
        margin-top: 5px;
        font-size: 13px;
        color: #b74b48;
    }

    &__group {
        position: relative;
        width: 100%;

        > input {
            width: 100%;
            outline: none;
            background: $c-white;
            border: 1px solid #ccc;
            border-radius: 4px;
            font-family: $ff-ro;
            font-size: 12px;
            padding: 10px 20px;
        }

        /* upload */

        input[type=file] {
            position: absolute;
            left: 0;
            top: 0;
            opacity: 0;
        }

        /* custom checkbox */
        
        label {
            display: block;
            position: relative;
            padding-left: 35px;
            //margin-bottom: 12px;
            cursor: pointer;
            font-size: 12px;
            font-weight: $fw-bold;
            user-select: none;

            > i {
                margin-right: 7px;
            }

            input {
                position: absolute;
                opacity: 0;
                cursor: pointer;
            }

            .checkmark {
                position: absolute;
                top: 0;
                left: 0;
                height: 20px;
                width: 20px;
                background-color: #eee;
                border: 1px solid #dadada;
                border-radius: 4px;

                &::after {
                    content: "\f00c";
                    font-family: $ff-fa;
                    font-size: 10px;
                    color: $c-white;
                    font-weight: 400;
                    position: absolute;
                    display: none;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                }
            }

            &:hover input ~ .checkmark {
                background-color: #ccc;
            }

            input:checked ~ .checkmark {
                background-color: #027e55;

                &:after {
                    display: block;
                }
            }
        }
    }
}