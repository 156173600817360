$amp: '.staff';

#{$amp} {

    margin-bottom: 20px;
    padding: 10px 20px;
    width: 100%;
    text-align: center;

    &__img {
        display: block;
        cursor: pointer;
        margin-bottom: 12px;
        
        img {
            width: 100%;
            border-radius: 4px;
            box-shadow: 1px 1px 12px 0px rgba(0, 0, 0, 0.17);
        }
    }

    &__name {
        display: block;
        font-size: 16px;
        font-family: $ff-os;
        font-weight: $fw-semibold;
        color: #000000;
        margin-bottom: 5px;
    }

    &__titulus {
        font-size: 16px;
        color: #000000;
        margin-bottom: 7px;
        display: block;
        font-style: italic;
    }

    &__city {
        font-size: 14px;
        color: #000000;
        margin-bottom: 7px;
        display: block;

        @include desktop {
            font-size: 16px;
        }

        &:before {
            content: '\f5a0';
            font-family: $ff-fa;
            margin-right: 10px;
        }
    }

    &__mail {
        font-family: $ff-os;
        display: block;
        font-size: 12px;
        margin-bottom: 15px;
        word-break: break-all;

        /*a {
            &::before {
                content: '\f0e0';
                font-family: $ff-fa;
                margin-right: 8px;
            }
        }*/
    }

    &__more {
        font-family: $ff-os;
        font-weight: $fw-bold;
        font-size: 12px;
        color: #D0D0D0;
        background: #ffffff; 
        border: 1px solid #f8c446;
        border-radius: 6px;
        padding: 5px 30px;
        cursor: pointer;

        &:hover {
            color: #ffffff!important;
            background: #f8c446;
            text-decoration: none;
        }
    }
}