$amp: '.footer';

#{$amp} {
    background: #193D2A;
    padding-top: 30px;

    &__title {
        font-family: $ff-os;
        font-weight: $fw-bold;
        font-size: 14px;
        color: #FFFFFF;
        letter-spacing: 0.5px;
        line-height: 16px;
        margin-bottom: 20px;
        border-left: 2px solid #F8C301;
        padding-left: 15px;

        @include desktop {
            font-size: 18px;
        }

        &--empty {
            border: none;
        }
    }

    &__text {
        font-family: $ff-ro;
        font-size: 12px;
        color: #ccc;
        letter-spacing: calc-em(0.5px, 14px);
        
        > p {
            margin-bottom: 10px;
            line-height: 20px;
        }

        @include desktop {
            font-size: 13px;
            line-height: 42px;
        }
    }

    &__list {
        display: grid;
        grid-column: 2;
        grid-template-columns: repeat(2, 1fr);
    }

    &__li {
        list-style-type: none;
        margin-left: 10px;
        padding-left: 17px;
        line-height: 30px;

        a {
            font-family: $ff-ro;
            font-weight: $fw-semibold;
            font-size: 12px;
            color: #FFFFFF;
            letter-spacing: calc-em(0.44px, 10px);
            line-height: 30px;

            &:hover {
                color: #F8C301;
            }

            @include desktop {
                font-size: 14px;
            }
        }
    }

    &__icon {
        margin: 3px;
        
        img {
            width: 50px;
            background: $c-white;
        }

        &:hover {
            text-decoration: none;
        }
    }

    &__made-by {
        background: #769D3E;
        border-top: 1px solid #979797;
        width: 100%;
        height: 60px;
        justify-content: center;
        display: flex;
        align-items: center;
    }
}

