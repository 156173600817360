$amp: '.sponzor';

#{$amp} {
    position: relative;
    width: 240px;
    height: 150px;
    overflow: hidden;
    border-radius: 4px;
    margin: 0 auto 10px auto;
    display: none;
    float: left;

    @include tablet{
        width: 300px;
        height: 185px;
    }

    &__image {
        width: 100%;
        transition: all 0.2s;
        

        &:hover {
            transform: scale(1.2);
        }
    }

    &__title {
        font-size: 12px;
        font-family: $ff-ro;
        padding: 5px 20px;
        position: absolute;
        bottom: 0;
        background: #0000002c;
        color: #ffffff;
        text-align: center;
        width: 100%;
    }
}
.show {
    display: block;
}

.control-btn-container {
    margin-bottom: 20px;
    text-align: center;

    .btn {
        position: relative;
        font-size: 14px;
    }

    .active {
        position: relative;

        &:after {
            content: '';
            background: url(../../web/images/pagetitlebg.svg);
            position: absolute;
            left: 50%;
            width: 50px;
            height: 3px;
            transform: translateX(-50%);
            top: 35px;
        }
        
    }
}