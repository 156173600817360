$amp: '.magazin-nav';

#{$amp} {

    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 20px;

    &__prev {
        margin-right: 4px;

        img {
            width: 30px;
            height: 30px;
        }
    }

    &__next {
        img {
            width: 30px;
            height: 30px;
        }
    }

    &__info {
        font-family: $ff-os;
        font-weight: $fw-semibold;
        font-size: 12px;
        color: #CFCFCF;
        display: block;
        margin-right: 10px;
        line-height: 16px;

        @include tablet {
            margin-right: 30px;
        }

        span + span {
            &:before {
                content: '|';
                margin: 0 2px;

                @include tablet {
                    margin: 0 7px;
                }
            }
        }
    }
}