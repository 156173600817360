$amp: '.order';

#{$amp} {

    display: flex;
    flex-flow: column;
    flex-wrap: wrap;
    font-family: $ff-ro;
    font-size: 14px;
    margin-bottom: 10px;
    border-bottom: 1px solid #dedede;
    justify-content: space-between;
    padding-bottom: 8px;
    align-items: center;

    @include tablet {
        flex-flow: row;
    }

    &__id {
        font-weight: $fw-semibold;
        margin: 0 10px;
    }

    &__name {
        line-height: 26px;
        margin: 0 10px;
    }

    &__date {
        line-height: 26px;
        margin: 0 10px;

        &:before {
            content: '\f017';
            font-family: $ff-fa;
            font-style: normal;
            margin-right: 8px;
            font-weight: $fw-light;
        }
    }

    &__type {
        line-height: 26px;
        margin: 0 10px;
    }

    &__download {
        padding: 6px 20px;
        background: #f8c301;
        color: #ffffff;
        border-radius: 4px;
        cursor: pointer;
        transition: 0.4s;

        &:hover,
        &:active,
        &:focus {
            transform: scale(0.95);
            text-decoration: none;
        }
    }
}