.profil {

    .form {
        
        @include tablet {
            display: grid;
            grid-column: 2;
            grid-template-columns: repeat(2, 1fr);
        }
    }

    .form__item {
        margin: 0 20px 15px;
    }

    .button-wrapper {
        display: flex;
        justify-content: center;
    }
}