$amp: '.magazin-data';

#{$amp} {

    margin-bottom: 20px;

    &__row {
        background: #F5F5F5;
        display: flex;
        flex-flow: column;
        margin-bottom: -1px;
        
        &:nth-child(2n) {
            background: #E9E9E9;
        }

        @include tablet {
            flex-flow: row;
        }
    }

    &__title {
        display: flex;
        flex-wrap: wrap;
        font-family: $ff-ro;
        font-size: 12px;
        color: #000000;
        padding: 10px 16px;
        width: 100%;
        position: relative;
        align-items: center;
        border-bottom: transparent;
        
        margin-right: -1px;
        margin-bottom: -1px;

        @include tablet {
            font-size: 13px;
            margin-bottom: 0;
            max-width: 170px;
        }

        > a {
            > i {
                margin-right: 10px;
            }
        }
    }

    &__content {

        font-family: $ff-ro;
        font-weight: $fw-bold;
        font-size: 12px;
        color: #424242;
        line-height: 16px;
        padding: 10px 16px;
        width: 100%;

        .study-image {
            width: 100px;
            height: 50px;
            display: table-cell;
            vertical-align: middle;
            float: left;
            overflow: hidden;
            text-align: center;
            border: 1px solid #dedede;
            margin-right: 20px;

            &:after {
                content: '';
                display: none;
            }

            > img {
                max-width: 100%;
                max-height: 100%;
            }
        }

        @include tablet {
            font-size: 13px;
        }

        > a {
            display: inline-block;
            font-family: $ff-ro;
            font-weight: $fw-bold;
            font-size: 12px;
            color: #4da2ff;
            line-height: 20px;
            cursor: pointer;

            &:hover,
            &:active,
            &:focus {
                color: #4da2ff !important;
            }

            @include tablet {
                font-size: 14px;
            }

            &:after {
                content: '|';
                margin: 0px 4px;
                color: #d2d2d2;
            }
        }
    }

    &__abstract, &__literature {
        font-family: "Open Sans",Helvetica,Arial,sans-serif;
        font-size: 14px;
        color: #323232;
        line-height: 20px;
        padding: 13px 0;

        @include tablet {
            font-size: 14px;
        }
    }

    &__info {
        font-family: $ff-ro;
        font-style: italic;
        font-size: 12px;
        color: #809852;
        padding-left: 20px;
        position: relative;

        &::before {
            content: '\f129';
            font-family: $ff-fa;
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
        }
    }
}