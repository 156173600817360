
@mixin placeholder {
    &:-moz-placeholder {
        @content;
        opacity: 1;
    }
    &::-moz-placeholder {
        @content;
        opacity: 1;
    }
    &:-ms-input-placeholder {
        @content;
    }
    &::-webkit-input-placeholder  {
        @content;
    }
}
