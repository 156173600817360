$amp: '.study';

#{$amp} {
    background: #FBFBFB;
    border: 1px solid #E4E4E4;
    padding: 70px 25px 25px 25px;
    margin-bottom: 20px;
    border-radius: 4px;
    overflow: hidden;
    position: relative;

    &__label {
        display: inline-block;
        font-family: $ff-os;
        font-weight: $fw-regular;
        font-size: 14px;
        color: #000000;
        line-height: 19px;
        text-transform: uppercase;
        margin-bottom: 7px;
        margin-right: 10px;

        &:after {
            content: "";
            display: block;
            height: 2px;
            background-image: url(../../web/images/label-bottom.svg);
            background-repeat: no-repeat;
            background-position: center bottom;
            background-size: auto;
        }
    }

    &__head {
        font-family: $ff-ro;
        font-weight: $fw-bold;
        font-size: 12px;
        color: #000000;
        line-height: 14px;
        background: #FFFFFF;
        position: absolute;
        top: 0;
        left: 0;
        padding: 15px 25px;
        width: 100%;

        > i {
            margin-right: 8px;
        }

        &--light {
            color: #8D8B8B;
        }
    }

    &__title {
        display: block;
        font-family: $ff-os;
        font-weight: $fw-bold;
        font-size: 12px;
        color: #000000;
        margin-bottom: 7px;
        line-height: 23px;
        text-transform: uppercase;

        @include media-breakpoint-up(md) {
            font-size: 14px;
        }
    }

    > hr {
        margin-top: 12px;
        margin-bottom: 12px;
    }

    &__keywords {
        display: block;
        font-family: $ff-ro;
        font-weight: $fw-bold;
        font-size: 13px;
        color: #818181;
        line-height: 16px;
        margin-top: 20px;
        margin-bottom: 20px;
    }

    &__magazin {
        font-family: $ff-ro;
        font-size: 13px;
        font-weight: 600;
        margin-bottom: 10px;
        color: #738d40;
        position: absolute;
        bottom: 20px;
        left: 20px;
        background: #ffffff;
        padding: 10px 15px;
    }
    
    &__author {
        font-family: $ff-ro;
        font-weight: $fw-bold;
        font-size: 12px;
        color: #000000;
        line-height: 14px;
        display: block;
        margin-top: 20px;
        margin-bottom: 20px;

        .link {
            display: inline-block;
            font-weight: 600;

            & + .link {
                &:before {
                    content: '|';
                    margin: 0 7px;
                }
            }
        }

        > b {
            font-weight: $fw-bold;
        }
    }

    &__doi {
        display: block;
        margin-top: 20px;
        margin-bottom: 20px;

        .link {
            font-family: $ff-ro;
            font-weight: $fw-bold;
            display: inline-block;
            font-weight: 600;
            font-size: 11px;

            @include media-breakpoint-up(md) {
                font-size: 13px;
            }
        }

        > b {
            font-weight: $fw-bold;
        }
    }

    &__link {
        display: block;
        font-family: $ff-ro;
        font-weight: $fw-bold;
        font-size: 12px;
        color: #809852;
        line-height: 16px;
        cursor: pointer;
        margin-bottom: 7px;

        @include tablet {
            font-size: 14px;
        }
    }

    &__more {

        font-family: $ff-os;
        font-weight: $fw-bold;
        font-size: 11px;
        color: #D0D0D0;
        background: transparent;
        border: 1px solid #f8c446;
        border-radius: 6px;
        padding: 6px 20px;
        cursor: pointer;
        display: inline-block;

        @include media-breakpoint-up(md) {
            font-size: 13px;
            padding: 8px 30px;
        }

        &:hover {
            color: #ffffff!important;
            background: #f8c446;
            text-decoration: none;
        }
    }
}