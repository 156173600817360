$amp: '.headline';

#{$amp} {
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    padding-bottom: 20px;

    @include tablet {
        flex-flow: row;
        padding-bottom: 30px;
    }

    &__title {
        font-family: $ff-os;
        font-weight: $fw-semibold;
        font-size: 20px;
        color: #000000;
        text-transform: uppercase;
        min-width: 120px;

        &::after {
            content: "";
            display: block;
            height: 20px;
            background-image: url("../../web/images/bottom-line.svg");
            background-repeat: no-repeat;
            background-position: left bottom;
        }
    }

    &__links {
        display: flex;
        flex-flow: column;
        align-items: center;
        margin-top: 10px;

        @include tablet {
            flex-flow: row;
        }

        > a {
            font-family: $ff-os;
            font-weight: $fw-bold;
            font-size: 12px;
            color: #6D7E40;
            line-height: 20px;

            @include tablet {
                font-size: 14px;
            }

            &:last-child {
                &:before {
                    content: '|';
                    display: none;
                    color: #CFCFCF;
                    margin: 0 10px;
                    font-size: 20px;

                    @include tablet {
                        display: inline-block;
                    }
                }
            }
        }
    }
}