
.slick-loading {
    background: #fff ("../images/ajax-loader.gif") center center no-repeat;
}

.slick-prev, .slick-next {
    width: 30px;
    height: 30px;
    position: relative;
    flex-basis: 30px;
    flex-shrink: 0;

    @include tablet {
        flex-basis: 40px;
        flex-shrink: 0;
        width: 40px;
        height: 40px;
    }

    > img {
        width: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
    }
}

.slick-prev {
    left: 0;
}

.slick-next {
    right: 0;
}