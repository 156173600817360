.stat-page {
    .two-part {
        &__sidebar {
            display: none;

            @include tablet {
                display: block;
            }
        }
    }
}