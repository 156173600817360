$amp: '.stat-text';

#{$amp} {
    font-family: $ff-os;
    font-size: 14px;
    color: #323232;
    line-height: 20px;
    margin-bottom: 30px;
    font-weight: 400;

    a {
        font-weight: 400;
        font-size: 14px;
        font-family: "Roboto", sans-serif;
        color: #738D40;
    }

    strong, b {
        font-weight: 600;
    }

    li {
        list-style: none;
        margin-left: 10px;
        padding-left: 17px;
        line-height: 21px;
        margin-bottom: 10px;

        &::before {
            content: '';
            display: inline-block;
            height: 10px;
            width: 10px;
            background-image: url(../../web/images/list.svg);
            vertical-align: middle;
            margin-right: 10px;
        }
     }
}