$amp: '.magazin';

#{$amp} {

    margin-bottom: 30px;

    &__img {
        width: 100%;
        margin-bottom: 15px;
        text-align: center;
        position: relative;

        .flag {
            position: absolute;
            top: 1px;
            left: 1px;
            background: #025dbc;
            padding: 6px 12px;
            text-transform: uppercase;
            font-size: 10px;
            font-weight: 900;
            color: #fff;
        }

        img {
            width: 100%;
            border: 1px solid #738D40;
            box-shadow: 2px 2px 10px 0 rgba(0,0,0,0.50);
        }

        &:hover {
            .magazin__btn {
                opacity: 1;
            }
        }
    }

    &__btn {

        font-family: $ff-os;
        font-weight: $fw-bold;
        font-size: 12px;
        color: #D0D0D0;
        background: #ffffff;
        border: 1px solid #f8c446;
        border-radius: 6px;
        padding: 8px 30px;
        cursor: pointer;
        display: inline-block;
        outline: none;

        > i {
            margin-right: 10px;
        }

        &:hover {
            color: #ffffff!important;
            background: #f8c446;
            text-decoration: none;
            outline: none;
        }
    }

    &__link {
        color: #7e9849;
        font-weight: 900;
        text-align: center;
        text-transform: uppercase;
        font-size: 13px;
        margin-top: 15px;
        display: block;

        > i {
            margin-right: 8px;
        }
    }

    &__title {
        font-family: $ff-os;
        font-weight: $fw-semibold;
        font-size: 16px;
        color: #000000;
        text-align: center;
        display: block;
        text-transform: uppercase;
        margin-bottom: 20px;

        &:hover {
            text-decoration: none;
        }

        &::after {
            content: "";
            display: block;
            height: 20px;
            background-image: url("../../web/images/bottom-line.svg");
            background-repeat: no-repeat;
            background-position: center bottom;
        }
    }
}