$amp: '.document';

#{$amp} {

    border:1px solid #f2f2f2;
    background: #f7f7f7;
    margin-bottom: 20px;
    padding: 10px 20px;
    width: 100%;
    border-radius: 4px;

    &__title {
        display: block;
        font-size: 14px;
        font-weight: $fw-semibold;
        color: #000000;
        margin-bottom: 10px;
        font-family: $ff-os;

        @include desktop {
            font-size: 16px;
        }
    }

    &__desc {
        font-size: 13px;
        color: #000000;
        margin-bottom: 10px;
        font-family: $ff-ro;
        line-height: 17px;
    }

    &__download {
        font-size: 14px;
        color: #000000;
        position: relative;
        padding: 10px 30px;
        display: inline-block;
        border:1px solid #dad8d8;
        border-radius: 4px;
        cursor: pointer;
        background: $c-white;

        &:before {
            content: '\f019';
            font-family: $ff-fa;
            margin-right: 5px;
            position: absolute;
            top: 50%;
            left: 10px;
            transform: translateY(-50%);
        }
    }
}