body {
    height: 100%;
    width: 100%;
    min-width: 320px;
    user-select: none;
    -webkit-font-smoothing: antialiased;

    @include desktop {
        background: url("../../web/images/bg.jpg") center top;
        background-repeat: no-repeat;
        background-attachment: fixed;
        background-image: cover;
    }

    .wrapper {
        position: relative;
        display: flex;
        flex-direction: column;
        max-width: 1200px;
        height: 100%;
        min-width: 320px;
        min-height: 100vh;
        margin: 0 auto;
        background: #FFFFFF;
    }

    .content {
        display: flex;
        flex-flow: column;
        flex: 1;
        flex-grow: 1;
        padding: 20px 0;

        @include media-breakpoint-up(md) {
            padding: 40px 15px;
        }
    }

    .order-page {
        display: flex;
        flex-flow: column;

        @include desktop {
            flex-flow: row;
        }

        &__left {
            display: flex;
            flex-wrap: wrap;
            flex-grow: 1;
            width: 100%;
        }

        &__right {
            
            @include desktop {
                padding-left: 40px;
                flex-shrink: 0;
                flex-basis: 600px;
            }
        }
    }

    .two-part {

        display: flex;
        flex-flow: column;

        @include desktop {
            flex-flow: row;
        }

        &__sidebar {

            display: none;

            @include desktop {
                display: block;
                padding-left: 40px;
                flex-shrink: 0;
                flex-basis: 300px;
            }
        }

        &__main {
            display: flex;
            flex-wrap: wrap;
            flex-grow: 1;
        }

        &--magazin {
            .two-part__sidebar {
                display: block;

                @include media-breakpoint-down(md) {
                    order: 1;
                }
            }

            .two-part__main {
                order: 2;
            }
        }
    }

    .page-title {
        font-size: 20px;
        text-align: center;
        display: block;
        text-transform: uppercase;
        margin-bottom: 30px;
        position: relative;

        @include desktop {
            margin-bottom: 50px;
        }

        &:after {
            content: '';
            background: url(../../web/images/pagetitlebg.svg);
            position: absolute;
            left: 50%;
            width: 100px;
            height: 3px;
            transform: translateX(-50%);
            top: 35px;
        }
    } 
}

.card-title {
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
    color: #8b9298;
}

.preview {
    > a {
        display: block !important;
        margin: 6px 0;
        font-size: 16px;
    }
}

.facebook-login {
    border-radius: 4px;
    margin: 0 auto;
    max-height: 40px;
    cursor: pointer;
    transition: 0.4s;

    &:hover,
    &:active,
    &:focus {
        transform: scale(0.95);
        text-decoration: none;
    }
}

.link {
    font-size: 14px;
    font-family: $ff-ro;
    color: #738D40;
    text-align: center;
    display: block;
}

a:hover {
    color: #F8C301!important;
}

p {
    margin-bottom: 10px;
}

b {
    font-weight: 700;
}

.goToTop {
    display: none;
    position: fixed;
    bottom: 20px;
    right: 30px;
    z-index: 99;
    font-size: 0;
    border: 1px solid #cacaca;
    outline: none;
    background-color: $c-white;
    color: #000000;
    cursor: pointer;
    padding: 20px;
    border-radius: 50%;
    text-transform: uppercase;
    box-shadow: black 2px 2px 2px;
    opacity: 0.5;

    &:hover {
        opacity: 1;
    }

    &:before {
        content: '\f077';
        font-family: $ff-fa;
        font-size: 14px;
    }

    @include phone {
        font-size: 14px;
    }
}