$amp: '.breadcrumb';

#{$amp} {
    display: none;
   
    @include tablet {
        display: flex;
    }
    
    &__li {
        
        a {
            font-family: $ff-ro;
            font-size: 13px;
            letter-spacing: 0.71px;
            color: black;
        }

        a:not([href]) {
            color: #D0D0D0;

            &:hover,
            &:active,
            &:focus {
                color: #D0D0D0!important;
            }
        }

        &::after {
            content: '/';
            color: #D0D0D0;
            padding: 0 2px;
            margin-right: 2px;

        }

        &:last-child:after {
            content: '';
        }

        &--selected {
            font-family: $ff-ro;
            font-size: 13px;
            color: #6D7E40;
            font-weight: $fw-semibold;
        }
    }
}