.bb-bookblock {
	width: 100%;
	max-width: 700px;
    height: 250px;
	margin: 0 auto;
	position: relative;
	//z-index: 100;
	-webkit-perspective: 1300px;
	perspective: 1300px;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;

	@media (min-width: 400px) and (max-width: 568px) { 
		height: 380px;
	}

	@include media-breakpoint-only(sm) {
		height: 420px;
	}

	@include media-breakpoint-up(sm) {
		height: 500px;
	}
	@include media-breakpoint-up(md) {
		height: 520px;
	}
	@include media-breakpoint-up(lg) {
		height: 550px;
	}
}

.bb-page {
	position: absolute;
	-webkit-transform-style: preserve-3d;
	transform-style: preserve-3d;
	-webkit-transition-property: -webkit-transform;
	transition-property: transform;
}

.bb-vertical .bb-page {
	width: 50%;
	height: 100%;
	left: 50%;
	-webkit-transform-origin: left center;
	transform-origin: left center;
}

.bb-horizontal .bb-page {
	width: 100%;
	height: 50%;
	top: 50%;
	-webkit-transform-origin: center top;
	transform-origin: center top;
}

.bb-page > div,
.bb-outer,
.bb-content,
.bb-inner {
	position: absolute;
	height: 100%;
	width: 100%;
	top: 0;
	left: 0;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
}

.bb-vertical .bb-content {
	width: 200%;
}

.bb-horizontal .bb-content {
	height: 200%;
}

.bb-page > div {
	width: 100%;
	-webkit-transform-style: preserve-3d;
	transform-style: preserve-3d;
}

.bb-vertical .bb-back {
	-webkit-transform: rotateY(-180deg);
	transform: rotateY(-180deg);
}

.bb-horizontal .bb-back {
	-webkit-transform: rotateX(-180deg);
	transform: rotateX(-180deg);
}

.bb-outer {
	width: 100%;
	overflow: hidden;
	z-index: 999;
}

.bb-overlay, 
.bb-flipoverlay {
	background-color: rgba(0, 0, 0, 0.7);
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
	opacity: 0;
}

.bb-flipoverlay {
	background-color: rgba(0, 0, 0, 0.2);
}

.bb-bookblock.bb-vertical > div.bb-page:first-child,
.bb-bookblock.bb-vertical > div.bb-page:first-child .bb-back {
	-webkit-transform: rotateY(180deg);
	transform: rotateY(180deg);
}

.bb-bookblock.bb-horizontal > div.bb-page:first-child,
.bb-bookblock.bb-horizontal > div.bb-page:first-child .bb-back {
	-webkit-transform: rotateX(180deg);
	transform: rotateX(180deg);
}

/* Content display */
.bb-content {
	background: #fff;
}

.bb-vertical .bb-front .bb-content {
	left: -100%;
}

.bb-horizontal .bb-front .bb-content {
	top: -100%;
}

/* Flipping classes */
.bb-vertical .bb-flip-next,
.bb-vertical .bb-flip-initial {
	-webkit-transform: rotateY(-180deg);
	transform: rotateY(-180deg);
}

.bb-vertical .bb-flip-prev {
	-webkit-transform: rotateY(0deg);
	transform: rotateY(0deg);
}

.bb-horizontal .bb-flip-next,
.bb-horizontal .bb-flip-initial {
	-webkit-transform: rotateX(180deg);
	transform: rotateX(180deg);
}

.bb-horizontal .bb-flip-prev {
	-webkit-transform: rotateX(0deg);
	transform: rotateX(0deg);
}

.bb-vertical .bb-flip-next-end {
	-webkit-transform: rotateY(-15deg);
	transform: rotateY(-15deg);
}

.bb-vertical .bb-flip-prev-end {
	-webkit-transform: rotateY(-165deg);
	transform: rotateY(-165deg);
}

.bb-horizontal .bb-flip-next-end {
	-webkit-transform: rotateX(15deg);
	transform: rotateX(15deg);
}

.bb-horizontal .bb-flip-prev-end {
	-webkit-transform: rotateX(165deg);
	transform: rotateX(165deg);
}

.bb-item {
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	display: none;
}

.bb-item-img {
	width: 100%;
    height: auto;
}

/* No JS */
.no-js .bb-bookblock, 
.no-js ul.bb-custom-grid li {
	width: auto;
	height: auto;
}

.no-js .bb-item {
	display: block;
	position: relative;
}

.bb-custom-icon {
	margin: 0 10px;

	> i {
		margin: 0 5px;
	}
} 