@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i,800,800i');
@import url('https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i&subset=latin-ext');

// Mixins
@import "mixins/mixins";
@import "mixins/placeholder";

// Bootstrap
@import "bootstrap/bootstrap-grid";
@import "bootstrap/bootstrap-reboot";
@import "bootstrap/bootstrap";

//Calendar
@import "calendar/hello-week";

// Config
@import "config/reset";
@import "config/variables";
@import "config/helper";
@import "config/base";
@import "config/fonts";
@import "config/helper";

// Partials
@import "partials/header";
@import "partials/mobil-nav";
@import "partials/footer";
@import "partials/menu";
@import "partials/pagination";
@import "partials/stat-text";
@import "partials/headline";
@import "partials/breadcrumb";
@import "partials/kert";
@import "partials/sidebar-box";
@import "partials/partners";
@import "partials/attachments";
@import "partials/events-box";
@import "partials/prospektus";
@import "partials/cookiebar";

// Module
@import "moduls/forms";
@import "moduls/calendar";
@import "moduls/login";
@import "moduls/button";
@import "moduls/faq";
@import "moduls/news";
@import "moduls/documents";
@import "moduls/sponzor";
@import "moduls/language-selector";
@import "moduls/reference";
@import "moduls/staff";
@import "moduls/video";
@import "moduls/carrier";
@import "moduls/gallery";
@import "moduls/poster";
@import "moduls/lecture";
@import "moduls/magazin";
@import "moduls/magazin-data";
@import "moduls/magazin-nav";
@import "moduls/study";
@import "moduls/order";
@import "moduls/order-page";
@import "moduls/card";

// Pages
@import "pages/profil";
@import "pages/magazin-list";
@import "pages/magazin-end";
@import "pages/stat-text";
@import "pages/error";

// Select 2
@import "select2/select2";

// overwrite
@import "overwrite/slick";
@import "overwrite/calendar";
@import "overwrite/select2";
@import "overwrite/quill";



