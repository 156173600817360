$amp: '.kert-logos';

#{$amp} {
    width: 90%;
    margin: 0 auto;
    margin-bottom: 20px;

    @include tablet {
        margin-bottom: 0;
    }

    img {
        width: 100%;
    }

    &__esba {
        display: none;

        @include phone {
            display: block;
        }
    }

    &__esba-mobil {
        display: block;

        @include phone {
            display: none;
        }
    }
}