$amp: '.header';

#{$amp} {

    padding: 10px;
    
    @include desktop {
        padding: 30px;
    }

    &__logo {
        margin-bottom: 10px;
        max-width: 190px;
        max-height: 75px;


        @include media-breakpoint-down(sm) {
            margin-top: 20px;
        }

        @include tablet {
            max-width: 350px;
        }
    }

    &__slogen {
        font-family: $ff-os;
        font-weight: $fw-semibold;
        font-style: italic;
        font-size: 10px;
        color: #004930;
        letter-spacing: 0.81px;

        @include desktop {
            font-size: 11px;
        }
    }

    &__icon {
        margin-right: 10px;
    }

    &__link {
        font-family: $ff-os;
        font-weight: 400;
        font-size: 12px;
        color: #004930;
        letter-spacing: calc-em(1.09px, 16px);
        line-height: 22px;
        transition: 0.4s;
        margin-bottom: 20px;
        align-items: center;
        display: flex;

        & + & {
            margin-left: 30px;
        }

        &:hover,
        &:active,
        &:focus {
            transform: scale(0.95);
            text-decoration: none;
        }

        &:last-child {
            margin-right: 0;
        }

        @include desktop {
            font-size: 16px;
        }
    }

    .form__item {
        width: 250px;
        margin-bottom: 0;
        display: none;

        input {
            border: 1px solid #6D7E40;
            border-radius: 8px;
            color: #D0D0D0;
            letter-spacing: 0.95px;
            padding: 12px 16px;
            text-align: center;
            font-weight: $fw-light;
        }

        @include desktop {
            display: block;
        }
    }

    .button {
        text-align: center;
        background: #738D40;
        padding: 6px 16px;
        position: relative;
        margin-left: 20px;

        @include tablet {
            width: auto;
        }
    }
}