.flex-row {
    display: flex;
    flex-flow: row;
}

.flex-column {
    display: flex;
    flex-flow: column;
}

.pb20 {
    padding-bottom: 20px;
}

.mb10 {
    margin-bottom: 10px;
}

.mb20 {
    margin-bottom: 20px;
}

.mb30 {
    margin-bottom: 30px;
}

.mb40 {
    margin-bottom: 40px;
}

.mb50 {
    margin-bottom: 50px;
}

.mt10 {
    margin-top: 10px;
}

.mt20 {
    margin-top: 20px;

}
.mt40 {
    margin-top: 40px;
}

.ta-center {
    text-align: center;
}
