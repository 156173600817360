$amp : '.nav';

#{$amp} {

    text-align: center;

    &__list {
        background: #738D40;
        border-top: 1px solid #F8C301;
        border-bottom: 1px solid #F8C301;
        padding: 0 2%;
        position: absolute;
        top: 95px;
        width: 100%;
        display: none;
        z-index: 99;
        margin-bottom: 0;

    
        @include tablet {
            display: block;
            position: unset;
        }
    }
    &__li {
        list-style-type: none;
        display: block;
        position: relative;
        
        a,  a:not([href]):not([tabindex]) {
            font-family: $ff-os;
            font-weight: 700;
            font-size: 12px;
            color: #FFFFFF;
            letter-spacing: calc-em(1.09px, 15px);
            padding: 13px 20px;
            border-bottom: 1px solid #657b3a;
            width: 100%;
            font-family: $ff-os;
            font-weight: 700;
            color: #FFFFFF;
            cursor: pointer;
            display: block;
            user-select: none;
    
            &:hover {
                color: #F8C301;
            }

            @include tablet {
                padding: 11px 14px;
                border: none;
                width: auto;
            }

            @include desktop {
                font-size: 14px;
                padding: 13px 15px;
                line-height: 17px;
            }
        }

        @include tablet {
            display: inline-block;
            border-right: 1px solid #6b7755;
            
            &:first-child {
                border-left: 1px solid #6b7755;
            }
        }
       
    }
    &__parent-nav {

        @include tablet {
            padding: 0 10px;
        }
        
        &::after {
            content:"\f054";
            font-family: $ff-fa;
            color: #ffffff;
            top: 13px;
            position: absolute;
            right: 20px;
            font-size: 10px;
            pointer-events: none;


            @include tablet {
                content: '\f078';
                right: 10px;
                top: 13px;
            }

            @include desktop {
                right: 10px;
                top: 17px;
            }
        }
    }

    &__parent-nav-opened {
        &::after {
            content:'\f077';
        }
    }

    &__sub-nav {
        display: none;
        background: #739631;
        //margin-top: 10px;
        //border-radius: 4px;
        z-index: 99;
        min-width: 200px;
        margin-left: -10px;

        @include tablet {
            position: absolute;
        }

        li {

            width: 100%;

            @include tablet {
                border-bottom: 1px solid #657b3a;
            }

            a {
                font-size: 12px;

                @include desktop {
                    font-size: 12px;
                }
            }
        }

        &--lvl-2 {
            background: #738d40;
            position: relative;
            width: 100%;
        }
    }
}
    
    