@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i,800,800i|Roboto:300,300i,400,400i,500,500i,700,700i,900,900i&subset=latin-ext');

h1 {
    font-size: 26px;
    line-height: 30px;
}

h2 {
    font-size: 23px;
    line-height: 27px;
}

h3 {
    font-size: 20px;
    line-height: 24px;
}

h4 {
    font-size: 17px;
    line-height: 21px;
}

h5 {
    font-size: 14px;
    line-height: 18px;
}

h6 {
    font-size: 11px;
    line-height: 15px;
}

@font-face {
    font-family: 'slick';
    src: url('../fonts/slick.eot');
    src: url('../fonts/slick.eot?#iefix') format('embedded-opentype'),
        url('../fonts/slick.woff2') format('woff2'),
        url('../fonts/slick.woff') format('woff'),
        url('../fonts/slick.ttf') format('truetype'),
        url('../fonts/slick.svg#slick') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'slick';
    src: url('../fonts/slick_1.eot');
    src: url('../fonts/slick_1.eot?#iefix') format('embedded-opentype'),
        url('../fonts/slick_1.woff2') format('woff2'),
        url('../fonts/slick_1.woff') format('woff'),
        url('../fonts/slick_1.ttf') format('truetype'),
        url('../fonts/slick_1.svg#slick') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'slick';
    src: url('../fonts/slick_2.eot');
    src: url('../fonts/slick_2.eot?#iefix') format('embedded-opentype'),
        url('../fonts/slick_2.woff2') format('woff2'),
        url('../fonts/slick_2.woff') format('woff'),
        url('../fonts/slick_2.ttf') format('truetype'),
        url('../fonts/slick_2.svg#slick') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Font Awesome 5 Free';
    src: url('../fonts/fontawesome/FontAwesome5FreeRegular.eot');
    src: url('../fonts/fontawesome/FontAwesome5FreeRegular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/fontawesome/FontAwesome5FreeRegular.woff2') format('woff2'),
        url('../fonts/fontawesome/FontAwesome5FreeRegular.woff') format('woff'),
        url('../fonts/fontawesome/FontAwesome5FreeRegular.ttf') format('truetype'),
        url('../fonts/fontawesome/FontAwesome5FreeRegular.svg#FontAwesome5FreeRegular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Font Awesome 5 Brands';
    src: url('../fonts/fontawesome/FontAwesome5BrandsRegular.eot');
    src: url('../fonts/fontawesome/FontAwesome5BrandsRegular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/fontawesome/FontAwesome5BrandsRegular.woff2') format('woff2'),
        url('../fonts/fontawesome/FontAwesome5BrandsRegular.woff') format('woff'),
        url('../fonts/fontawesome/FontAwesome5BrandsRegular.ttf') format('truetype'),
        url('../fonts/fontawesome/FontAwesome5BrandsRegular.svg#FontAwesome5BrandsRegular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Font Awesome 5 Free';
    src: url('../fonts/fontawesome/FontAwesome5FreeSolid.eot');
    src: url('../fonts/fontawesome/FontAwesome5FreeSolid.eot?#iefix') format('embedded-opentype'),
        url('../fonts/fontawesome/FontAwesome5FreeSolid.woff2') format('woff2'),
        url('../fonts/fontawesome/FontAwesome5FreeSolid.woff') format('woff'),
        url('../fonts/fontawesome/FontAwesome5FreeSolid.ttf') format('truetype'),
        url('../fonts/fontawesome/FontAwesome5FreeSolid.svg#FontAwesome5FreeSolid') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Font Awesome 5 Free';
    src: url('../fonts/fontawesome/fontawesome-free.eot');
    src: url('../fonts/fontawesome/fontawesome-free.eot?#iefix') format('embedded-opentype'),
        url('../fonts/fontawesome/fontawesome-free.woff2') format('woff2'),
        url('../fonts/fontawesome/fontawesome-free.woff') format('woff'),
        url('../fonts/fontawesome/fontawesome-free.ttf') format('truetype'),
        url('../fonts/fontawesome/fontawesome-free.svg#fontawesome-free') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Font Awesome 5 Brands';
    src: url('../fonts/fontawesome/fontawesome-free_1.eot');
    src: url('../fonts/fontawesome/fontawesome-free_1.eot?#iefix') format('embedded-opentype'),
        url('../fonts/fontawesome/fontawesome-free_1.woff2') format('woff2'),
        url('../fonts/fontawesome/fontawesome-free_1.woff') format('woff'),
        url('../fonts/fontawesome/fontawesome-free_1.ttf') format('truetype'),
        url('../fonts/fontawesome/fontawesome-free_1.svg#fontawesome-free') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Font Awesome 5 Free';
    src: url('../fonts/fontawesome/fontawesome-free_2.eot');
    src: url('../fonts/fontawesome/fontawesome-free_2.eot?#iefix') format('embedded-opentype'),
        url('../fonts/fontawesome/fontawesome-free_2.woff2') format('woff2'),
        url('../fonts/fontawesome/fontawesome-free_2.woff') format('woff'),
        url('../fonts/fontawesome/fontawesome-free_2.ttf') format('truetype'),
        url('../fonts/fontawesome/fontawesome-free_2.svg#fontawesome-free') format('svg');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Font Awesome 5 Free';
    src: url('../fonts/fontawesome/FontAwesome5FreeRegular_1.eot');
    src: url('../fonts/fontawesome/FontAwesome5FreeRegular_1.eot?#iefix') format('embedded-opentype'),
        url('../fonts/fontawesome/FontAwesome5FreeRegular_1.woff2') format('woff2'),
        url('../fonts/fontawesome/FontAwesome5FreeRegular_1.woff') format('woff'),
        url('../fonts/fontawesome/FontAwesome5FreeRegular_1.ttf') format('truetype'),
        url('../fonts/fontawesome/FontAwesome5FreeRegular_1.svg#FontAwesome5FreeRegular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Font Awesome 5 Brands';
    src: url('../fonts/fontawesome/FontAwesome5BrandsRegular_1.eot');
    src: url('../fonts/fontawesome/FontAwesome5BrandsRegular_1.eot?#iefix') format('embedded-opentype'),
        url('../fonts/fontawesome/FontAwesome5BrandsRegular_1.woff2') format('woff2'),
        url('../fonts/fontawesome/FontAwesome5BrandsRegular_1.woff') format('woff'),
        url('../fonts/fontawesome/FontAwesome5BrandsRegular_1.ttf') format('truetype'),
        url('../fonts/fontawesome/FontAwesome5BrandsRegular_1.svg#FontAwesome5BrandsRegular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Font Awesome 5 Free';
    src: url('../fonts/fontawesome/FontAwesome5FreeSolid_1.eot');
    src: url('../fonts/fontawesome/FontAwesome5FreeSolid_1.eot?#iefix') format('embedded-opentype'),
        url('../fonts/fontawesome/FontAwesome5FreeSolid_1.woff2') format('woff2'),
        url('../fonts/fontawesome/FontAwesome5FreeSolid_1.woff') format('woff'),
        url('../fonts/fontawesome/FontAwesome5FreeSolid_1.ttf') format('truetype'),
        url('../fonts/fontawesome/FontAwesome5FreeSolid_1.svg#FontAwesome5FreeSolid') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Font Awesome 5 Free';
    src: url('../fonts/fontawesome/FontAwesome5FreeSolid_2.eot');
    src: url('../fonts/fontawesome/FontAwesome5FreeSolid_2.eot?#iefix') format('embedded-opentype'),
        url('../fonts/fontawesome/FontAwesome5FreeSolid_2.woff2') format('woff2'),
        url('../fonts/fontawesome/FontAwesome5FreeSolid_2.woff') format('woff'),
        url('../fonts/fontawesome/FontAwesome5FreeSolid_2.ttf') format('truetype'),
        url('../fonts/fontawesome/FontAwesome5FreeSolid_2.svg#FontAwesome5FreeSolid') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Font Awesome 5 Brands';
    src: url('../fonts/fontawesome/FontAwesome5BrandsRegular_2.eot');
    src: url('../fonts/fontawesome/FontAwesome5BrandsRegular_2.eot?#iefix') format('embedded-opentype'),
        url('../fonts/fontawesome/FontAwesome5BrandsRegular_2.woff2') format('woff2'),
        url('../fonts/fontawesome/FontAwesome5BrandsRegular_2.woff') format('woff'),
        url('../fonts/fontawesome/FontAwesome5BrandsRegular_2.ttf') format('truetype'),
        url('../fonts/fontawesome/FontAwesome5BrandsRegular_2.svg#FontAwesome5BrandsRegular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Font Awesome 5 Free';
    src: url('../fonts/fontawesome/FontAwesome5FreeRegular_2.eot');
    src: url('../fonts/fontawesome/FontAwesome5FreeRegular_2.eot?#iefix') format('embedded-opentype'),
        url('../fonts/fontawesome/FontAwesome5FreeRegular_2.woff2') format('woff2'),
        url('../fonts/fontawesome/FontAwesome5FreeRegular_2.woff') format('woff'),
        url('../fonts/fontawesome/FontAwesome5FreeRegular_2.ttf') format('truetype'),
        url('../fonts/fontawesome/FontAwesome5FreeRegular_2.svg#FontAwesome5FreeRegular') format('svg');
    font-weight: normal;
    font-style: normal;
}



