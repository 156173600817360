/* style for selects until select2 loads */
select {
    width: 100%;
    height: 44px;
    border: 1px solid black;
    outline: none;
    border-radius: 4px;

    &[multiple] {
        height: 46px;

        option {
            width: 0;
            padding: 0;
            overflow: hidden;
            position: absolute;
        }
    }
}

.select2-container--default .select2-selection--single {
    width: 100%;
    outline: none;
    border: none;
    border-radius: 4px;
    background: $c-white;
    padding: 0!important;
    height: 38px;
}

.select2-container--default 
        .select2-selection--single 
        .select2-selection__arrow {

            height: 38px !important;
            top: 0 !important;
}

.select2-container {
    outline: none;

    .select2-selection__rendered {
        padding: 5px 15px;
        padding-left: 15px;
        color:$c-black;
        font-size: 14px;
        line-height: normal;
        font-family: $ff-ro;
        font-weight: $fw-bold;
        border: 1px solid #dadada;
        border-radius: 4px;
    }

    &.select2-container--disabled {
        .select2-selection--single {
            cursor: default;
        }
    }

    &.select2-container--above.select2-container--open {
        border-radius: 0 0 4px 4px;
    }

    &.select2-container--below.select2-container--open {
        border-radius: 4px 4px 0 0;
    }

    &.select2-container--open {

        .select2-dropdown {
            left: -1px;

            &--above {
                border-bottom: 1px solid #dadada;
            }
        }
        .select2-container--default 
        .select2-selection--single 
        .select2-selection__arrow {

            height: 38px !important;
            top: 0 !important;
            
            > b {
                border-color: transparent transparent #dadada transparent;
                border-width: 0 4px 5px 4px;
            }
        }
    }

    // Dropdown search input
    .select2-search--dropdown {
        .select2-search__field {
            border: 1px solid #dadada;
            outline: none;
        }
    }

    // Dropdown
    .select2-results > .select2-results__options {
        max-height: 200px;
        overflow-y: auto;
    }

    .select2-results__option {
        padding: 8px 13px;
        font-size: 14px;
    }

    // Selected option

    .select2-results__option[aria-selected=true] {
        /*background-color: $c-porcelain;
        color: $c-gray-chateau;*/
        background-color: #dadada;
        color: #000000;
        position: relative;
    }

    .select2-results__option--highlighted,
    .select2-results__option--highlighted.select2-results__option--highlighted[aria-selected="true"] {
        color: #000000;
        background-color:#dadada;
    }

    .select2-dropdown {
        font-family: "Roboto";
    }
}

.select2-container--default {
    border-radius: 4px;

    .select2-selection--single {
        padding: 8px;
        border-radius: 4px;

        .select2-selection__rendered {
            font-size: 14px;
        }
    }


    .select2-results__option {
        padding: 8px;
    }

    .select2-dropdown {
        font-size: 12px;
    }
}
