.jsCalendar {
    display: flex;
    justify-content: center;
}

.jsCalendar thead .jsCalendar-week-days th, .jsCalendar tbody td {
    font-size: 11px;
    height: 25px;
    width: 25px;
}

.jsCalendar thead .jsCalendar-title-name {
    font-size: 15px;
}

.jsCalendar thead .jsCalendar-title {
    display: flex;
    justify-content: center;
}
.jsCalendar thead .jsCalendar-week-days th, .jsCalendar tbody td {
    line-height: 26px;
}

// fullcalendar
.fc-content {
    cursor: pointer;
    padding: 10px;
    word-break: break-all;
    color: white;
    font-size: 10px;
    font-family: $ff-ow;
    font-weight: $fw-semibold;
}
.fc-bgevent {
    opacity: 1;
    margin: 1px;
    display: block;
    height: 97%;
    cursor: pointer;
}

.fc-day-number {
    margin-right: 10px;
    margin-top: 10px;
    font-weight: $fw-regular;
    font-family: $ff-ro;
    font-size: 12px;
}

.fc-other-month.fc-future {
    background: #e2e2e2;
}