.editor-text, .stat-text {
    font-family: $ff-os;
    font-size: 14px;
    color: #323232;
    line-height: 18px;
    
    em {
        font-style: italic;
    }

    strong {
        font-weight: $fw-bold;
    }

    pre {
        background: #7d9849;
        color: white;
        padding: 18px 26px;
        border-radius: 4px;
    }

    ul, ol {

        li {
            list-style: none;
            margin-left: 10px;
            padding-left: 17px;
            line-height: 21px;
            margin-bottom: 10px;
        
            &:before {
                content: '';
                display: inline-block;
                height: 10px;
                width: 10px;
                background-image: url(../../web/images/list.svg);
                vertical-align: middle;
                margin-right: 10px;
            }
        }
    }

    blockquote {
        border-left: 4px solid #7d9849;
        padding-left: 15px;
    }

    img {
        max-width: 100%;
    }

    .ql-size-small {
        font-size: 10px;
        line-height: 14px;
    }

    .ql-size-large {
        font-size: 18px;
        line-height: 22px;
    }

    .ql-size-huge {
        font-size: 20px;
        line-height: 24px;
    }

    .ql-align-justify {
        text-align: justify;
    }

    .ql-align-center {
        text-align: center;
    }

    .ql-align-right {
        text-align: right;
    }

}