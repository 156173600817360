.mobil-nav {
    width: 45px;
    height: 45px;
    border-radius: 4px;
    position: absolute;
    cursor: pointer;
    top: 25px;
    background: #738D40;
    right: 20px;
    

    @include tablet {
        display: none;
    }


    > span {
        display: block;
        height: 2px;
        left: 50%;
        transform: translateX(-50%);
        position: absolute;
        background: #ffffff;
        border-radius: 0;
        width: 20px;

        &:first-child {
            top: 13px;
        }

        &:nth-child(2) {
            top: 21px;
        }
        &:nth-child(3) {
            top: 29px;
        }
    }
}