/* Error */
.page-body-wrapper.full-page-wrapper {
	width: 100%;
	min-height: 100vh;
	display: flex;
    flex-direction: row;
    padding-left: 0;
    padding-right: 0;
}

.content-wrapper {
	padding: 1.5rem 1.7rem;
    width: 100%;
    flex-grow: 1;
}

.error-page {

	background: #738D40;
	font-family: $ff-ro;
	
	h1 {
		font-size: 9.375rem;
		line-height: 1;
		@media (max-width: 991px) {
			font-size: 8rem;
		}
	}

	h2 {
		font-size: 4.375rem;
		line-height: 1;
	}

	.error-page-divider {
		@media (min-width: 992px) {
			border-left: 3px solid rgba($white, .2);
		}
	}
}