$amp: '.order-page';

#{$amp} {
    .magazin-year {
        margin-bottom: 0;
    }
    &__row {
        display: flex;
        justify-content: space-between;
        padding: 15px 0;
        border-bottom: 1px solid #ECECEC;

        &:last-child {
            border-bottom: none;
        }

        @include tablet {
            flex-flow: row;
        }
    }

    &__title {
        font-family: $ff-ro;
        font-weight: 600;
        font-size: 12px;
        color: #afafaf;
        text-transform: uppercase;
        display: flex;
        align-items: center;
        margin-bottom: 10px;

        @include tablet {
            font-size: 14px;
        }
    }

    &__subtitle {
        font-family: $ff-ro;
        font-weight: $fw-semibold;
        font-style: italic;
        display: block;
        font-size: 13px;
        color: #000000;
        margin-bottom: 20px;
    }

    .form__item {
        margin-bottom: 10px;

        @include tablet {
            margin-bottom: 0;
        }

        .form__group {
            line-height: 20px;

            label {
                margin-bottom: 0;
            }
        }
    }

    &__quantity {
        display: flex;
        align-items: center;

        .form__row {
            margin-right: 15px;

            .form__item {
                margin-bottom: 0;

                input {
                    text-align: center;
                }
            }
        }
    }

    &__desc {
        font-family: $ff-ro;
        font-size: 14px;
        color: #000000;
        line-height: 20px;
    }

    &__price {
        justify-items: flex-start;
        font-family: $ff-ro;
        font-weight: $fw-bold;
        font-size: 14px;
        color: #898c82;
        display: flex;
        align-items: center;

        @include media-breakpoint-up(md) {
            font-size: 18px;
        }
    }

    &__delivery {
        display: flex;
        justify-content: space-between;
        font-family: $ff-ro;
        font-size: 14px;
        color: #000000;

        @include media-breakpoint-up(md) {
            font-size: 16px;
        }
    }

    &__summary {
        display: flex;
        justify-content: space-between;
        font-family: $ff-ro;
        font-weight: $fw-bold;
        font-size: 16px;
        color: #000000;
        margin-bottom: 40px;

        @include media-breakpoint-up(md) {
            font-size: 20px;
        }
    }

    &__link {
        display: inline-block;
        font-size: 12px;
        font-family: "Roboto", sans-serif;
        color: #738D40;

        > i {
            margin-right: 10px;
        }
    }
}

.order-page__right {

    .form__item {
        margin-bottom: 20px;

        .form__group label {
            font-weight: $fw-regular;
            font-size: 12px;
            color: #222222;
        }
    }

    .button {
        display: flex;
        justify-content: center;
        padding: 12px 16px;
        font-size: 14px;
    }
}