$amp: '.poster';

#{$amp} {

    margin-bottom: 40px;

    &__img {
        margin-bottom: 10px;
        position: relative;
        width: 100%;
        overflow: hidden;
        cursor: pointer;

        img {
            width: 100%;
            border-radius: 4px;
            transition: 0.2s;

            &:hover {
                transform: scale(1.1);
            }
        }
        &::after {
            content: '\f002';
            font-family: $ff-fa;
            font-size: 16px;
            position: absolute;
            top: 20px;
            right: 20px;
            cursor: pointer;
        }
    }

    &__title {
        display: block;
        font-family: $ff-os;
        font-weight: $fw-semibold;
        font-size: 14px;
        color: #000000;
        margin-bottom: 7px;
        line-height: 20px;
    }
}