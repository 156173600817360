$amp: '.gallery';

#{$amp} {
    margin-bottom: 40px;

    &__item {
        display: none;

        &:first-child {
            display: block;
        }
    }

    &__img {
        display: inline-block;
        width: 200px;
        height: 120px;
        position: relative;
        overflow: hidden;
        cursor: pointer;
        border: 1px solid #d4d4d4;

        > a {
            display: table-cell;
            vertical-align: middle;
            text-align: center;
            width: 200px;
            height: 120px;
        }

        img {
            max-width: 100%;
            max-height: 100%;
            transition: 0.2s;

            &:hover {
                transform: scale(1.1);
            }
        }
        &::after {
            content: '\f002';
            font-family: $ff-fa;
            font-size: 16px;
            position: absolute;
            top: 20px;
            right: 20px;
            cursor: pointer;
            color: #000000;
        }

        
    }

    &__list-img {
        display: block;
        position: relative;
        overflow: hidden;
        cursor: pointer;
        margin-bottom: 7px;

        img {
            width: 100%;
            border-radius: 4px;
        }
    }

    &__title {
        display: block;
        font-family: $ff-os;
        font-weight: $fw-semibold;
        font-size: 14px;
        color: #000000;
        margin-bottom: 7px;
        line-height: 20px;

        @include tablet {
            font-size: 15px;
        }
    }

    &__desc {
        font-family: $ff-ro;
        font-weight: $fw-regular;
        font-size: 12px;
        color: #948F8F;
        margin-bottom: 20px;
        line-height: 18px;
        margin-bottom: 10px;

        @include tablet {
            font-size: 14px;
        }
    }
}