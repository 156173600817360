.magazin-year {
    font-family: $ff-os;
    font-weight: $fw-bold;
    font-size: 13px;
    color: #FFFFFF;
    width: 100%;
    background-image: linear-gradient(90deg, #738D40 16%, #FFFFFF 100%);
    border-radius: 2px;
    padding: 12px 20px;
    margin-bottom: 20px;

    @include tablet {
        font-size: 16px;
    }
}