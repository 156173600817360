$amp: '.language-selector';

#{$amp} {
    padding: 0 10px;

    @include phone {
        margin-left: 30px;
        padding: 0;
    }

    > a {
        transition: 0.4s;
        margin-right: 30px;
        display: inline-block;
        
        &:last-child {
            margin-right: 0;
        }

        img {
            &:hover {
                transform: scale(0.95);
            }
        }
    }
}