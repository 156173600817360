$amp: '.video';

#{$amp}{

    margin-bottom: 40px;

    &__frame {
        margin-bottom: 10px;
        position: relative;

        &--bg {
            background: #ffffff;
            width: 100%;
            height: 200px;
            border: 1px solid #f2f2f2;
            &::after {
                content: '\f167';
                font-family: $ff-brands;
                font-size: 50px;
                color: #ff0001;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%,-50%);
    
            }
        }
    }

    &__title {
        display: block;
        font-family: $ff-os;
        font-weight: $fw-semibold;
        font-size: 14px;
        color: #000000;
        margin-bottom: 7px;
        line-height: 20px;

        @include tablet {
            font-size: 16px;
        }
    }

    &__desc {
        font-family: $ff-ro;
        font-weight: $fw-regular;
        font-size: 12px;
        color: #948F8F;
        margin-bottom: 20px;
        line-height: 18px;

        @include tablet {
            font-size: 14px;
        }
    }
}