$amp: '.lecture';

#{$amp} {

    border:1px solid #f2f2f2;
    padding: 20px;
    margin-bottom: 30px;

    &__title {
        display: block;
        font-family: $ff-os;
        font-weight: $fw-semibold;
        font-size: 14px;
        color: #000000;
        margin-bottom: 7px;
        line-height: 20px;

        @include tablet {
            font-size: 15px;
        }
    }

    &__date {
        display: block;
        font-family: $ff-ro;
        font-weight: $fw-light;
        font-size: 12px;
        color: #000000;
        margin-bottom: 10px;

        > b {
            font-weight: $fw-semibold;

            &:after {
                content: '-';
                margin: 0 6px;
            }
        }

        &:before {
            content: '\f017';
            font-family: $ff-fa;
            font-style: normal;
            margin-right: 8px;
            font-weight: $fw-light;
        }
    }

    &__desc {
        font-family: $ff-ro;
        font-weight: $fw-regular;
        font-size: 12px;
        color: #948F8F;
        margin-bottom: 20px;
        line-height: 18px;

        @include tablet {
            font-size: 14px;
        }
    }
}